import React, { useEffect, useState } from 'react';
import './Menu.css';
import { AdminPortal, ContextHolder } from "@frontegg/react";

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    console.log(`isOpen: ${isOpen}`);
  }, [isOpen]);

  const handleClick = () => {
    AdminPortal.show();
  };

  const logout = () => {
    const baseUrl = ContextHolder.getContext().baseUrl;
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
  };

  return (
    <div className='menu'>
      <nav className="menu">
        <div onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </nav>
      <ul className={`menu-items ${isOpen ? 'open' : ''}`}>
        <li onClick={handleClick}>Admin Portal</li>
        <li onClick={() => logout()}>Logout</li>
      </ul>
    </div>
  );
};

export default Menu;
