import "./App.scss";
import {FronteggProvider} from "@frontegg/react";

import {FronteggConfiguration} from "./components/FronteggConfiguration";
import {UserAuthenticatedComponent} from "./components/UserAuthenticatedComponent";
import {useLocalStorage} from "./hooks/useLocalStorage";

const themeOptions = {
    adminPortal: {
      layout: {
        fullScreenMode: true
      }
    }
  };


const authOptions= {
    routes:{
    hostedLoginRedirectUrl:"/oauth/callback",
    authenticatedUrl:"/"
}}

function App() {
    const [baseUrl, setBaseUrl] = useLocalStorage(
        "frontegg-demo-base-url",
        "https://example.frontegg.com"
    );

    const [clientId, setClientId] = useLocalStorage(
        "frontegg-demo-client-id",
        "example-frontegg-client-id"
    );

    return (
        <div className="App">
            <FronteggProvider
                authOptions = {authOptions}
                themeOptions={themeOptions}
                hostedLoginBox={true}
                contextOptions={{
                    tenantResolver: () => {
                      const urlQueryParams = new URLSearchParams(window.location.search);
                      const organization = urlQueryParams.get('organization');
                      return {
                        tenant: organization
                      }
                    },
                    clientId,
                    baseUrl,
                  }}
            >
                <FronteggConfiguration
                    i_clientId={clientId}
                    i_setClientId={setClientId}
                    i_baseUrl={baseUrl}
                    i_setBaseUrl={setBaseUrl}
                >
                    <UserAuthenticatedComponent />
                </FronteggConfiguration>
            </FronteggProvider>
        </div>
    );
}

export default App;
