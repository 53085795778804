import { useAuth, useLoginWithRedirect } from "@frontegg/react";
import { useState } from "react";
import "../InputStyle.sass";
import "../index.css"

export function FronteggConfiguration({
  i_clientId,
  i_setClientId,
  i_baseUrl,
  i_setBaseUrl,
  children,
}) {
  const { isAuthenticated } = useAuth();
  const loginWithRedirect = useLoginWithRedirect();

  const [changeConfiguration, setChangeConfiguration] = useState(false);

  const [baseUrl, setBaseUrl] = useState(i_baseUrl);
  const [clientId, setClientId] = useState(i_clientId);

  const configurationButtonClicked = () => {
    if (changeConfiguration) {
      i_setBaseUrl(baseUrl);
      i_setClientId(clientId);
      window.location.reload();
    }
    setChangeConfiguration(!changeConfiguration);
  };

  console.log(`isauth=${isAuthenticated}`);
  return isAuthenticated ? (
    children
  ) : (
    <div className="config-page">
      <h3>Try your Frontegg account using your base URL and client ID</h3>
      <button
        className="config-button"
        disabled={changeConfiguration}
        onClick={() => loginWithRedirect()}
      >
        Login
      </button>
      <h4>Or</h4>
      <div>
        <button
          onClick={() => configurationButtonClicked()}
          children={changeConfiguration ? "Save" : "Change configuration"}
        ></button>
      </div>
      <div>
        <span>Base URL:</span>
        <input
          onInput={(e) => {
            setBaseUrl(e.target.value);
          }}
          disabled={!changeConfiguration}
          name="base-url-input"
          value={baseUrl}
          type={"text"}
        />
        <span>Client ID:</span>
        <input
          onInput={(e) => {
            setClientId(e.target.value);
          }}
          disabled={!changeConfiguration}
          name="client-id-input"
          value={clientId}
          type={"text"}
        />
      </div>
      <div>
        <h4>Just make sure you put {window.location.href} in your 'Application URL' as following</h4>
        <img alt='example' src={'/cra-frontegg-demo/APP_URL.png'}></img>
      </div>
    </div>
  );
}
