import React, { useEffect } from "react";
import { useAuth, useLoginWithRedirect, AdminPortal, useTenantsState, useAuthActions } from "@frontegg/react";
import { Link } from "react-router-dom";
import Menu from './Menu';

export function UserAuthenticatedComponent() {
    const { user, isAuthenticated } = useAuth();
    const loginWithRedirect = useLoginWithRedirect();
    const { switchTenant} = useAuthActions();
    const { tenants } = useTenantsState();

    useEffect(() => {
        if (!isAuthenticated) {
        console.log("Redirecting")
        loginWithRedirect();
        }
    }, [isAuthenticated, loginWithRedirect]);


    const copyValue = (e) => {
        const val = e.target.value;
        navigator.clipboard.writeText(val);
        e.target.value = "copied!";
        setInterval(() => {
        e.target.value = val;
        }, 2000);
    };

    const switchTenantFromDropdown =(e) => {
        const selectedIndex = e.target.selectedIndex
        const newTenantId = tenants[selectedIndex].tenantId
        switchTenant({ tenantId: newTenantId });
    }

    return (
        <div className="app-authenticated">
        {isAuthenticated ? (
            <div className="authenticated-page">

                <div className="left-side">

                    <div className="account-picker">
                        <span><b>Switch account:</b><br/><br/></span>
                        <select className="tenant-selector" onChange={switchTenantFromDropdown}>
                            {tenants.map((option, index) => (
                            option.tenantId === user.tenantId ? 
                            <option key={index} value={option.name} selected="selected">{option.name}</option>
                            :
                            <option key={index} value={option.name}>{option.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                
                <div className="right-side">
                    
                    <div className="top-menu">
                        <div className="user-profile-pic">
                            <img src={user.profilePictureUrl} alt={user.name} className="profile-pic" referrerPolicy="no-referrer"/>
                        </div>
                        
                        <div>
                            <span>{user.email}
                            {user.roles.map((role, index) => {
                                <p key={index}>{role.name}</p>
                            })}</span>
                        </div>
                        <Menu />
                    </div>
                    <div className="main-page">

                        <h2>Here are your user details:</h2>

                        <h4>User id</h4>
                        <textarea cols="35" onClick={(e) => {copyValue(e);}}>{user.id}</textarea>

                        <h4>Active tenant id</h4>
                        <textarea cols="35" onClick={(e) => {copyValue(e);}}>{user.tenantId}</textarea>

                        <h4>JWT</h4>
                        <textarea className="textarea-data" cols="100" rows="45" onClick={(e) => {copyValue(e);}}>{user.accessToken}</textarea>
                    </div>
                </div>

            </div>
        ) : (
            <div className="user-zone">
            <p>Not logged in</p>
            <button>
                <Link to={`oauth/account/login`}>login</Link>
            </button>
            </div>
        )}
        </div>
    );
}
